<template>
    <b-card no-body header-bg-variant="bluenavy" header="Portale EPS" header-text-variant="white" header-tag="h2" border-variant="bluenavy">
        <b-card-text>
            <b-alert show variant="secondary" dismissible>
                <h4 class="alert-heading text-center">Inserimento attività Sportiva - Formativa - Didattica</h4>

                <h6 class="alert-heading">INSERIMENTO ATTIVITÀ NAZIONALI:</h6>
                <p>
                    In questa sezione inserisci le gare Regionali/Provinciali che organizzi (con i relativi partecipanti) e che sono riferibili ad uno degli eventi nazionali precaricati dalla Segreteria Nazionale.
                </p>
                <hr>
                <p>
                    Ti ricordiamo che gli eventi nazionali possono essere inseriti esclusivamente dalla Segreteria Nazionale.
                </p>

                <h6 class="alert-heading">INSERIMENTO ATTIVITÀ REGIONALI/PROVINCIALI:</h6>
                <p>
                    In questa sezione potrai inserire eventi Regionali/Provinciali (con le relative gare e partecipanti) organizzati dal tuo Comitato.
                </p>
            </b-alert>
        </b-card-text>

        <div class="row">
            <div class="col-md-12 p-7">
                <div class="form-group">
                    <label for="idannuality" class="font-weight-bolder">Annualità:</label>
                    <select class="form-control" id="idannuality" v-model="idannuality">
                        <option v-for="annuality in annualities" :value="annuality.id">{{annuality.solarsannualitydisplay}}</option>
                    </select>
                </div>
            </div>
        </div>

        <b-tabs card justified v-model="tabIndex">
            <b-tab class="border border-success border-top-0 rounded-bottom" title="Attività Sportiva Nazionale" :title-link-class="linkClass(0)">
                <b-card-text>
                    <b-alert show variant="secondary" dismissible>
                        <p>
                            Ricerca una Attività Sportiva Nazionale ed inserisci le gare cliccando sul bottone apposito.
                        </p>
                        <p>
                            Se l'evento nazionale che stai cercando non è presente contatta la Segreteria Nazionale.
                        </p>
                    </b-alert>

                    <b-alert show variant="danger" dismissible>
                        <p>Attenzione, l'eliminazione degli eventi e dei partecipanti comporterà anche l’eliminazione dal Registro CONI.</p>
                        <p v-if="$store.getters.currentUser.usertype === 'Superadmin' || $store.getters.currentUser.usertype === 'Segreteria Nazionale'">Utilizzare l'azione cestino <i class="fas fa-trash-alt text-white"></i> e i pulsanti delle operazioni massive con cautela.</p>
                        <p v-if="$store.getters.currentUser.usertype === 'Comitato'">Utilizzare l'azione cestino <i class="fas fa-trash-alt text-white"></i> con cautela.</p>
                    </b-alert>
                </b-card-text>

                <SmartTable ref="nationalsportingevents" url="/eps/national-sporting-event/index" :is_dialog="true"
                            base_url="/eps/national-sporting-event" base_path="/" :fixed_filters="[{ name: 'national', filter: true }, { name: 'idannuality', filter: idannuality }]" custom_class="mx-0 px-0" v-on:close-modal="closeModalNationalSportingEvents">
                    <template v-slot:title>
                        <h2 class="card-title"><span class="card-label font-weight-bolder text-dark font-size-h3">Attività Sportiva Nazionale</span></h2>
                    </template>

                    <template v-slot:title-actions="slotProps">
                        <div v-if="slotProps.values.top_actions.length" class="card-toolbar">
                            <a v-for="(action, index) in slotProps.values.top_actions" href="#" :class="'mt-2 btn btn-' + action.class + ' font-weight-bolder mr-3'" @click.prevent="slotProps.values.doAction(action)">
                                <i v-if="action.icon" :class="action.icon"></i>
                                {{action.label}}
                            </a>

                            <a v-if="false && ($store.getters.currentUser.usertype === 'Superadmin' || $store.getters.currentUser.usertype === 'Segreteria Nazionale')" href="#" class="mt-2 btn btn-primary font-weight-bolder mr-3" @click.prevent="sendSelectedEvents('nationalsportingevents')">
                                <i class="fas fa-paper-plane"></i>
                                Invia Eventi Selezionati
                            </a>

                            <a v-if="false && ($store.getters.currentUser.usertype === 'Superadmin' || $store.getters.currentUser.usertype === 'Segreteria Nazionale')" href="#" class="mt-2 btn btn-danger font-weight-bolder mr-3" @click.prevent="removeSelectedEvents('nationalsportingevents')">
                                <i class="fas fa-trash-alt"></i>
                                Rimuovi Eventi Selezionati
                            </a>

                            <a v-if="false && ($store.getters.currentUser.usertype === 'Superadmin' || $store.getters.currentUser.usertype === 'Segreteria Nazionale')" href="#" class="mt-2 btn btn-primary font-weight-bolder mr-3" @click.prevent="sendSelectedCompetitors('nationalsportingevents')">
                                <i class="fas fa-paper-plane"></i>
                                Invia Partecipanti Selezionati
                            </a>

                            <a v-if="false && ($store.getters.currentUser.usertype === 'Superadmin' || $store.getters.currentUser.usertype === 'Segreteria Nazionale')" href="#" class="mt-2 btn btn-danger font-weight-bolder" @click.prevent="removeSelectedCompetitors('nationalsportingevents')">
                                <i class="fas fa-trash-alt"></i>
                                Rimuovi Partecipanti Selezionati
                            </a>
                        </div>
                    </template>

                    <template v-slot:td-conierrorstate="slotProps">
                        <td class="text-center">
                            <div v-if="typeof slotProps.values !== 'undefined'">
                                <a v-if="slotProps.values.item[slotProps.values.field.name] === 'unknown'" href="#" title="Vai al monitoraggio..." alt="Vai al monitoraggio..." class="badge pill bg-info text-white text-hover-white" style="font-size: 0.9em;" @click.prevent="tabIndex = 5"><i class="fas fa-hourglass-start text-white"></i> Creato</a>
                                <a v-if="slotProps.values.item[slotProps.values.field.name] === 'partial'" href="#" title="Vai al monitoraggio..." alt="Vai al monitoraggio..." class="badge pill bg-primary text-white text-hover-white" @click.prevent="tabIndex = 5"><i class="fas fa-paper-plane text-white"></i> Invio Parziale</a>
                                <a v-if="slotProps.values.item[slotProps.values.field.name] === 'success'" href="#" title="Vai al monitoraggio..." alt="Vai al monitoraggio..." class="badge pill bg-success text-white text-hover-white" @click.prevent="tabIndex = 5"><i class="fas fa-check text-white"></i> Inviato il {{slotProps.values.item.conisenddate}}</a>

                                <a v-if="slotProps.values.item[slotProps.values.field.name] === 'error'" href="#" title="Vai al monitoraggio..." alt="Vai al monitoraggio..." class="badge pill bg-danger text-white text-hover-white" @click.prevent="tabIndex = 5"><i class="fa fa-times text-white"></i> Errore Gen.</a>
                                <a v-if="slotProps.values.item[slotProps.values.field.name] === 'mincompetitions'" href="#" title="Vai al monitoraggio..." alt="Vai al monitoraggio..." class="badge pill bg-danger text-white text-hover-white" @click.prevent="tabIndex = 5"><i class="fas fa-less-than text-white"></i> Min. Gare</a>
                                <a v-if="slotProps.values.item[slotProps.values.field.name] === 'error_no_competitors_found'" href="#" title="Vai al monitoraggio..." alt="Vai al monitoraggio..." class="badge pill bg-danger text-white text-hover-white" @click.prevent="tabIndex = 5"><i class="fas fa-user-alt-slash text-white"></i></i> Nessun. Part.</a>
                                <a v-if="slotProps.values.item[slotProps.values.field.name] === 'error_no_competitors_sent'" href="#" title="Vai al monitoraggio..." alt="Vai al monitoraggio..." class="badge pill bg-danger text-white text-hover-white" @click.prevent="tabIndex = 5"><i class="fas fa-people-arrows text-white"></i></i> Ness. Part. Inv.</a>
                                <a v-if="slotProps.values.item[slotProps.values.field.name] === 'error_competitors_partial'" href="#" title="Vai al monitoraggio..." alt="Vai al monitoraggio..." class="badge pill bg-danger text-white text-hover-white" @click.prevent="tabIndex = 5"><i class="fas fa-user-friends text-white"></i></i> Inv. Parz. Part.</a>
                            </div>
                        </td>
                    </template>

                        <template v-slot:item-actions="slotProps">
                            <td v-if="(typeof slotProps.values.item.actions !== 'undefined') && slotProps.values.item.actions.length" class="text-nowrap pr-0 text-center">
                                <a href="#" class="btn btn-icon btn-circle btn-sm btn-info mr-3" title="Gestione Gare" alt="Gestione Gare" @click.prevent="$router.push('/eps-national-sporting-competition/' + slotProps.values.item.id)">
                                    <i class="fas fa-cogs"></i>
                                </a>

                                <template v-for="(action, j) in slotProps.values.item.actions">
                                    <a v-if="typeof action.is_external === 'undefined' || !action.is_external" href="javascript:void(0);" :class="'btn btn-icon btn-circle btn-sm btn-' + action.class + ((j < (slotProps.values.item.actions.length - 1) ? ' mr-3' : ''))" :title="action.label" :alt="action.label" @click.prevent="slotProps.values.doItemAction(action, slotProps.values.item['id'])">
                                        <i :class="action.icon"></i>
                                    </a>

                                    <a v-else :href="slotProps.values.appendToken(action.url)" target="_blank" :class="'btn btn-icon btn-circle btn-sm btn-' + action.class + ((j < (slotProps.values.item.actions.length - 1) ? ' mr-3' : ''))" :title="action.label" :alt="action.label">
                                        <i :class="action.icon"></i>
                                    </a>
                                </template>
                            </td>
                        </template>
                </SmartTable>
            </b-tab>

            <b-tab class="border border-success border-top-0 rounded-bottom" title="Attività Formativa Nazionale" :title-link-class="linkClass(1)">
                <b-card-text>
                    <b-alert show variant="secondary" dismissible>
                        <p>
                            Ricerca una Attività Formativa Nazionale.
                        </p>
                        <p>
                            Se l'evento nazionale che stai cercando non è presente contatta la Segreteria Nazionale.
                        </p>
                    </b-alert>

                    <b-alert show variant="danger" dismissible>
                        <p>Attenzione, l'eliminazione degli eventi e dei partecipanti comporterà anche l’eliminazione dal Registro CONI.</p>
                        <p v-if="$store.getters.currentUser.usertype === 'Superadmin' || $store.getters.currentUser.usertype === 'Segreteria Nazionale'">Utilizzare l'azione cestino <i class="fas fa-trash-alt text-white"></i> e i pulsanti delle operazioni massive con cautela.</p>
                        <p v-if="$store.getters.currentUser.usertype === 'Comitato'">Utilizzare l'azione cestino <i class="fas fa-trash-alt text-white"></i> con cautela.</p>
                    </b-alert>
                </b-card-text>

                <SmartTable ref="nationaltrainingevents" url="/eps/national-training-event/index" :is_dialog="true"
                            base_url="/eps/national-training-event" base_path="/" :fixed_filters="[{ name: 'national', filter: true }, { name: 'idannuality', filter: idannuality }]" custom_class="mx-0 px-0" v-on:close-modal="closeModalNationalTrainingEvents">
                    <template v-slot:title>
                        <h2 class="card-title"><span class="card-label font-weight-bolder text-dark font-size-h3">Attività Formativa Nazionale</span></h2>
                    </template>

                    <template v-slot:title-actions="slotProps">
                        <div v-if="slotProps.values.top_actions.length" class="card-toolbar">
                            <a v-for="(action, index) in slotProps.values.top_actions" href="#" :class="'mt-2 btn btn-' + action.class + ' font-weight-bolder mr-3'" @click.prevent="slotProps.values.doAction(action)">
                                <i v-if="action.icon" :class="action.icon"></i>
                                {{action.label}}
                            </a>

                            <a v-if="false && ($store.getters.currentUser.usertype === 'Superadmin' || $store.getters.currentUser.usertype === 'Segreteria Nazionale')" href="#" class="mt-2 btn btn-primary font-weight-bolder mr-3" @click.prevent="sendSelectedEvents('nationaltrainingevents')">
                                <i class="fas fa-paper-plane"></i>
                                Invia Eventi Selezionati
                            </a>

                            <a v-if="false && ($store.getters.currentUser.usertype === 'Superadmin' || $store.getters.currentUser.usertype === 'Segreteria Nazionale')" href="#" class="mt-2 btn btn-danger font-weight-bolder mr-3" @click.prevent="removeSelectedEvents('nationaltrainingevents')">
                                <i class="fas fa-trash-alt"></i>
                                Rimuovi Eventi Selezionati
                            </a>

                            <a v-if="false && ($store.getters.currentUser.usertype === 'Superadmin' || $store.getters.currentUser.usertype === 'Segreteria Nazionale')" href="#" class="mt-2 btn btn-primary font-weight-bolder mr-3" @click.prevent="sendSelectedCompetitors('nationaltrainingevents')">
                                <i class="fas fa-paper-plane"></i>
                                Invia Partecipanti Selezionati
                            </a>

                            <a v-if="false && ($store.getters.currentUser.usertype === 'Superadmin' || $store.getters.currentUser.usertype === 'Segreteria Nazionale')" href="#" class="mt-2 btn btn-danger font-weight-bolder" @click.prevent="removeSelectedCompetitors('nationaltrainingevents')">
                                <i class="fas fa-trash-alt"></i>
                                Rimuovi Partecipanti Selezionati
                            </a>
                        </div>
                    </template>

                    <template v-slot:td-conierrorstate="slotProps">
                        <td class="text-center">
                            <div v-if="typeof slotProps.values !== 'undefined'">
                                <a v-if="slotProps.values.item[slotProps.values.field.name] === 'unknown'" href="#" title="Vai al monitoraggio..." alt="Vai al monitoraggio..." class="badge pill bg-info text-white text-hover-white" style="font-size: 0.9em;" @click.prevent="tabIndex = 5"><i class="fas fa-hourglass-start text-white"></i> Creato</a>
                                <a v-if="slotProps.values.item[slotProps.values.field.name] === 'partial'" href="#" title="Vai al monitoraggio..." alt="Vai al monitoraggio..." class="badge pill bg-primary text-white text-hover-white" @click.prevent="tabIndex = 5"><i class="fas fa-paper-plane text-white"></i> Invio Parziale</a>
                                <a v-if="slotProps.values.item[slotProps.values.field.name] === 'success'" href="#" title="Vai al monitoraggio..." alt="Vai al monitoraggio..." class="badge pill bg-success text-white text-hover-white" @click.prevent="tabIndex = 5"><i class="fas fa-check text-white"></i> Inviato il {{slotProps.values.item.conisenddate}}</a>

                                <a v-if="slotProps.values.item[slotProps.values.field.name] === 'error'" href="#" title="Vai al monitoraggio..." alt="Vai al monitoraggio..." class="badge pill bg-danger text-white text-hover-white" @click.prevent="tabIndex = 5"><i class="fa fa-times text-white"></i> Errore Gen.</a>
                                <a v-if="slotProps.values.item[slotProps.values.field.name] === 'mincompetitions'" href="#" title="Vai al monitoraggio..." alt="Vai al monitoraggio..." class="badge pill bg-danger text-white text-hover-white" @click.prevent="tabIndex = 5"><i class="fas fa-less-than text-white"></i> Min. Gare</a>
                                <a v-if="slotProps.values.item[slotProps.values.field.name] === 'error_no_competitors_found'" href="#" title="Vai al monitoraggio..." alt="Vai al monitoraggio..." class="badge pill bg-danger text-white text-hover-white" @click.prevent="tabIndex = 5"><i class="fas fa-user-alt-slash text-white"></i></i> Nessun. Part.</a>
                                <a v-if="slotProps.values.item[slotProps.values.field.name] === 'error_no_competitors_sent'" href="#" title="Vai al monitoraggio..." alt="Vai al monitoraggio..." class="badge pill bg-danger text-white text-hover-white" @click.prevent="tabIndex = 5"><i class="fas fa-people-arrows text-white"></i></i> Ness. Part. Inv.</a>
                                <a v-if="slotProps.values.item[slotProps.values.field.name] === 'error_competitors_partial'" href="#" title="Vai al monitoraggio..." alt="Vai al monitoraggio..." class="badge pill bg-danger text-white text-hover-white" @click.prevent="tabIndex = 5"><i class="fas fa-user-friends text-white"></i></i> Inv. Parz. Part.</a>
                            </div>
                        </td>
                    </template>

                    <template v-slot:item-actions="slotProps">
                        <td v-if="(typeof slotProps.values.item.actions !== 'undefined') && slotProps.values.item.actions.length" class="text-nowrap pr-0 text-center">
                            <a href="#" class="btn btn-icon btn-circle btn-sm btn-info mr-3" title="Gestione Partecipanti" alt="Gestione Partecipanti" @click.prevent="$router.push('/eps-subscription/event/' + slotProps.values.item.id)">
                                <i class="fas fa-sign-in-alt"></i>
                            </a>

                            <template v-for="(action, j) in slotProps.values.item.actions">
                                <a v-if="typeof action.is_external === 'undefined' || !action.is_external" href="javascript:void(0);" :class="'btn btn-icon btn-circle btn-sm btn-' + action.class + ((j < (slotProps.values.item.actions.length - 1) ? ' mr-3' : ''))" :title="action.label" :alt="action.label" @click.prevent="slotProps.values.doItemAction(action, slotProps.values.item['id'])">
                                    <i :class="action.icon"></i>
                                </a>

                                <a v-else :href="slotProps.values.appendToken(action.url)" target="_blank" :class="'btn btn-icon btn-circle btn-sm btn-' + action.class + ((j < (slotProps.values.item.actions.length - 1) ? ' mr-3' : ''))" :title="action.label" :alt="action.label">
                                    <i :class="action.icon"></i>
                                </a>
                            </template>
                        </td>
                    </template>
                </SmartTable>
            </b-tab>

            <b-tab class="border border-primary border-top-0 rounded-bottom" title="Attività Sportiva Regionale/Provinciale" :title-link-class="linkClass(2)">
                <b-card-text>
                    <b-alert show variant="secondary" dismissible>
                        <p>
                            Ricerca e gestisci le attività oppure inserisci una nuova Attività Sportiva.
                        </p>
                        <p>
                            L'evento sportivo è correttamente inserito solo se viene aggiunta almeno una gara, le società e come minimo due partecipanti. Ogni evento inserito che non rispecchi queste caratteristiche verrà segnalato in rosso e non sarà valido.
                        </p>
                        <p>
                            Ti invitiamo ad inserire correttamente i dati obbligatori richiesti.
                        </p>
                    </b-alert>

                    <b-alert show variant="danger" dismissible>
                        <p>Attenzione, l'eliminazione degli eventi e dei partecipanti comporterà anche l’eliminazione dal Registro CONI.</p>
                        <p v-if="$store.getters.currentUser.usertype === 'Superadmin' || $store.getters.currentUser.usertype === 'Segreteria Nazionale'">Utilizzare l'azione cestino <i class="fas fa-trash-alt text-white"></i> e i pulsanti delle operazioni massive con cautela.</p>
                        <p v-if="$store.getters.currentUser.usertype === 'Comitato'">Utilizzare l'azione cestino <i class="fas fa-trash-alt text-white"></i> con cautela.</p>
                    </b-alert>
                </b-card-text>

                <SmartTable ref="regionalsportingevents" url="/eps/regional-sporting-event/index" :is_dialog="true"
                            base_url="/eps/regional-sporting-event" base_path="/" :fixed_filters="[{ name: 'national', filter: false }, { name: 'idannuality', filter: idannuality }]" custom_class="mx-0 px-0" v-on:close-modal="closeModalRegionalSportingEvents">
                    <template v-slot:title>
                        <h2 class="card-title"><span class="card-label font-weight-bolder text-dark font-size-h3">Attività Sportiva Regionale/Provinciale</span></h2>
                    </template>

                    <template v-slot:title-actions="slotProps">
                        <div v-if="slotProps.values.top_actions.length" class="card-toolbar">
                            <a v-for="(action, index) in slotProps.values.top_actions" href="#" :class="'mt-2 btn btn-' + action.class + ' font-weight-bolder mr-3'" @click.prevent="slotProps.values.doAction(action)">
                                <i v-if="action.icon" :class="action.icon"></i>
                                {{action.label}}
                            </a>

                            <a v-if="false && ($store.getters.currentUser.usertype === 'Superadmin' || $store.getters.currentUser.usertype === 'Segreteria Nazionale')" href="#" class="mt-2 btn btn-primary font-weight-bolder mr-3" @click.prevent="sendSelectedEvents('regionalsportingevents')">
                                <i class="fas fa-paper-plane"></i>
                                Invia Eventi Selezionati
                            </a>

                            <a v-if="false && ($store.getters.currentUser.usertype === 'Superadmin' || $store.getters.currentUser.usertype === 'Segreteria Nazionale')" href="#" class="mt-2 btn btn-danger font-weight-bolder mr-3" @click.prevent="removeSelectedEvents('regionalsportingevents')">
                                <i class="fas fa-trash-alt"></i>
                                Rimuovi Eventi Selezionati
                            </a>

                            <a v-if="false && ($store.getters.currentUser.usertype === 'Superadmin' || $store.getters.currentUser.usertype === 'Segreteria Nazionale')" href="#" class="mt-2 btn btn-primary font-weight-bolder mr-3" @click.prevent="sendSelectedCompetitors('regionalsportingevents')">
                                <i class="fas fa-paper-plane"></i>
                                Invia Partecipanti Selezionati
                            </a>

                            <a v-if="false && ($store.getters.currentUser.usertype === 'Superadmin' || $store.getters.currentUser.usertype === 'Segreteria Nazionale')" href="#" class="mt-2 btn btn-danger font-weight-bolder" @click.prevent="removeSelectedCompetitors('regionalsportingevents')">
                                <i class="fas fa-trash-alt"></i>
                                Rimuovi Partecipanti Selezionati
                            </a>
                        </div>
                    </template>

                    <template v-slot:td-conierrorstate="slotProps">
                        <td class="text-center">
                            <div v-if="typeof slotProps.values !== 'undefined'">
                                <a v-if="slotProps.values.item[slotProps.values.field.name] === 'unknown'" href="#" title="Vai al monitoraggio..." alt="Vai al monitoraggio..." class="badge pill bg-info text-white text-hover-white" style="font-size: 0.9em;" @click.prevent="tabIndex = 5"><i class="fas fa-hourglass-start text-white"></i> Creato</a>
                                <a v-if="slotProps.values.item[slotProps.values.field.name] === 'partial'" href="#" title="Vai al monitoraggio..." alt="Vai al monitoraggio..." class="badge pill bg-primary text-white text-hover-white" @click.prevent="tabIndex = 5"><i class="fas fa-paper-plane text-white"></i> Invio Parziale</a>
                                <a v-if="slotProps.values.item[slotProps.values.field.name] === 'success'" href="#" title="Vai al monitoraggio..." alt="Vai al monitoraggio..." class="badge pill bg-success text-white text-hover-white" @click.prevent="tabIndex = 5"><i class="fas fa-check text-white"></i> Inviato il {{slotProps.values.item.conisenddate}}</a>

                                <a v-if="slotProps.values.item[slotProps.values.field.name] === 'error'" href="#" title="Vai al monitoraggio..." alt="Vai al monitoraggio..." class="badge pill bg-danger text-white text-hover-white" @click.prevent="tabIndex = 5"><i class="fa fa-times text-white"></i> Errore Gen.</a>
                                <a v-if="slotProps.values.item[slotProps.values.field.name] === 'mincompetitions'" href="#" title="Vai al monitoraggio..." alt="Vai al monitoraggio..." class="badge pill bg-danger text-white text-hover-white" @click.prevent="tabIndex = 5"><i class="fas fa-less-than text-white"></i> Min. Gare</a>
                                <a v-if="slotProps.values.item[slotProps.values.field.name] === 'error_no_competitors_found'" href="#" title="Vai al monitoraggio..." alt="Vai al monitoraggio..." class="badge pill bg-danger text-white text-hover-white" @click.prevent="tabIndex = 5"><i class="fas fa-user-alt-slash text-white"></i></i> Nessun. Part.</a>
                                <a v-if="slotProps.values.item[slotProps.values.field.name] === 'error_no_competitors_sent'" href="#" title="Vai al monitoraggio..." alt="Vai al monitoraggio..." class="badge pill bg-danger text-white text-hover-white" @click.prevent="tabIndex = 5"><i class="fas fa-people-arrows text-white"></i></i> Ness. Part. Inv.</a>
                                <a v-if="slotProps.values.item[slotProps.values.field.name] === 'error_competitors_partial'" href="#" title="Vai al monitoraggio..." alt="Vai al monitoraggio..." class="badge pill bg-danger text-white text-hover-white" @click.prevent="tabIndex = 5"><i class="fas fa-user-friends text-white"></i></i> Inv. Parz. Part.</a>
                            </div>
                        </td>
                    </template>

                    <template v-slot:item-actions="slotProps">
                        <td v-if="(typeof slotProps.values.item.actions !== 'undefined') && slotProps.values.item.actions.length" class="text-nowrap pr-0 text-center">
                            <a href="#" class="btn btn-icon btn-circle btn-sm btn-info mr-3" title="Gestione Gare" alt="Gestione Gare" @click.prevent="$router.push('/eps-regional-sporting-competition/' + slotProps.values.item.id)">
                                <i class="fas fa-cogs"></i>
                            </a>

                            <template v-for="(action, j) in slotProps.values.item.actions">
                                <a v-if="typeof action.is_external === 'undefined' || !action.is_external" href="javascript:void(0);" :class="'btn btn-icon btn-circle btn-sm btn-' + action.class + ((j < (slotProps.values.item.actions.length - 1) ? ' mr-3' : ''))" :title="action.label" :alt="action.label" @click.prevent="slotProps.values.doItemAction(action, slotProps.values.item['id'])">
                                    <i :class="action.icon"></i>
                                </a>

                                <a v-else :href="slotProps.values.appendToken(action.url)" target="_blank" :class="'btn btn-icon btn-circle btn-sm btn-' + action.class + ((j < (slotProps.values.item.actions.length - 1) ? ' mr-3' : ''))" :title="action.label" :alt="action.label">
                                    <i :class="action.icon"></i>
                                </a>
                            </template>
                        </td>
                    </template>
                </SmartTable>
            </b-tab>

            <b-tab class="border border-primary border-top-0 rounded-bottom" title="Attività Formativa Regionale/Provinciale" :title-link-class="linkClass(3)">
                <b-card-text>
                    <b-alert show variant="secondary" dismissible>
                        <p>
                            Ricerca e gestisci le attività oppure inserisci una nuova Attività Formativa.
                        </p>
                        <p>
                            L'evento formativo è correttamente inserito solo se vengono aggiunte le società ed almeno due partecipanti. Ogni evento inserito che non rispecchi queste caratteristiche verrà segnalato in rosso e non sarà valido.
                        </p>
                        <p>
                            Ti invitiamo ad inserire correttamente i dati obbligatori richiesti.
                        </p>
                    </b-alert>

                    <b-alert show variant="danger" dismissible>
                        <p>Attenzione, l'eliminazione degli eventi e dei partecipanti comporterà anche l’eliminazione dal Registro CONI.</p>
                        <p v-if="$store.getters.currentUser.usertype === 'Superadmin' || $store.getters.currentUser.usertype === 'Segreteria Nazionale'">Utilizzare l'azione cestino <i class="fas fa-trash-alt text-white"></i> e i pulsanti delle operazioni massive con cautela.</p>
                        <p v-if="$store.getters.currentUser.usertype === 'Comitato'">Utilizzare l'azione cestino <i class="fas fa-trash-alt text-white"></i> con cautela.</p>
                    </b-alert>
                </b-card-text>

                <SmartTable ref="regionaltrainingevents" url="/eps/regional-training-event/index" :is_dialog="true"
                            base_url="/eps/regional-training-event" base_path="/" :fixed_filters="[{ name: 'national', filter: false }, { name: 'idannuality', filter: idannuality }]" custom_class="mx-0 px-0" v-on:close-modal="closeModalRegionalTrainingEvents">
                    <template v-slot:title>
                        <h2 class="card-title"><span class="card-label font-weight-bolder text-dark font-size-h3">Attività Formativa Regionale/Provinciale</span></h2>
                    </template>

                    <template v-slot:title-actions="slotProps">
                        <div v-if="slotProps.values.top_actions.length" class="card-toolbar">
                            <a v-for="(action, index) in slotProps.values.top_actions" href="#" :class="'mt-2 btn btn-' + action.class + ' font-weight-bolder mr-3'" @click.prevent="slotProps.values.doAction(action)">
                                <i v-if="action.icon" :class="action.icon"></i>
                                {{action.label}}
                            </a>

                            <a v-if="false && ($store.getters.currentUser.usertype === 'Superadmin' || $store.getters.currentUser.usertype === 'Segreteria Nazionale')" href="#" class="mt-2 btn btn-primary font-weight-bolder mr-3" @click.prevent="sendSelectedEvents('regionaltrainingevents')">
                                <i class="fas fa-paper-plane"></i>
                                Invia Eventi Selezionati
                            </a>

                            <a v-if="false && ($store.getters.currentUser.usertype === 'Superadmin' || $store.getters.currentUser.usertype === 'Segreteria Nazionale')" href="#" class="mt-2 btn btn-danger font-weight-bolder mr-3" @click.prevent="removeSelectedEvents('regionaltrainingevents')">
                                <i class="fas fa-trash-alt"></i>
                                Rimuovi Eventi Selezionati
                            </a>

                            <a v-if="false && ($store.getters.currentUser.usertype === 'Superadmin' || $store.getters.currentUser.usertype === 'Segreteria Nazionale')" href="#" class="mt-2 btn btn-primary font-weight-bolder mr-3" @click.prevent="sendSelectedCompetitors('regionaltrainingevents')">
                                <i class="fas fa-paper-plane"></i>
                                Invia Partecipanti Selezionati
                            </a>

                            <a v-if="false && ($store.getters.currentUser.usertype === 'Superadmin' || $store.getters.currentUser.usertype === 'Segreteria Nazionale')" href="#" class="mt-2 btn btn-danger font-weight-bolder" @click.prevent="removeSelectedCompetitors('regionaltrainingevents')">
                                <i class="fas fa-trash-alt"></i>
                                Rimuovi Partecipanti Selezionati
                            </a>
                        </div>
                    </template>

                    <template v-slot:td-conierrorstate="slotProps">
                        <td class="text-center">
                            <div v-if="typeof slotProps.values !== 'undefined'">
                                <a v-if="slotProps.values.item[slotProps.values.field.name] === 'unknown'" href="#" title="Vai al monitoraggio..." alt="Vai al monitoraggio..." class="badge pill bg-info text-white text-hover-white" style="font-size: 0.9em;" @click.prevent="tabIndex = 5"><i class="fas fa-hourglass-start text-white"></i> Creato</a>
                                <a v-if="slotProps.values.item[slotProps.values.field.name] === 'partial'" href="#" title="Vai al monitoraggio..." alt="Vai al monitoraggio..." class="badge pill bg-primary text-white text-hover-white" @click.prevent="tabIndex = 5"><i class="fas fa-paper-plane text-white"></i> Invio Parziale</a>
                                <a v-if="slotProps.values.item[slotProps.values.field.name] === 'success'" href="#" title="Vai al monitoraggio..." alt="Vai al monitoraggio..." class="badge pill bg-success text-white text-hover-white" @click.prevent="tabIndex = 5"><i class="fas fa-check text-white"></i> Inviato il {{slotProps.values.item.conisenddate}}</a>

                                <a v-if="slotProps.values.item[slotProps.values.field.name] === 'error'" href="#" title="Vai al monitoraggio..." alt="Vai al monitoraggio..." class="badge pill bg-danger text-white text-hover-white" @click.prevent="tabIndex = 5"><i class="fa fa-times text-white"></i> Errore Gen.</a>
                                <a v-if="slotProps.values.item[slotProps.values.field.name] === 'mincompetitions'" href="#" title="Vai al monitoraggio..." alt="Vai al monitoraggio..." class="badge pill bg-danger text-white text-hover-white" @click.prevent="tabIndex = 5"><i class="fas fa-less-than text-white"></i> Min. Gare</a>
                                <a v-if="slotProps.values.item[slotProps.values.field.name] === 'error_no_competitors_found'" href="#" title="Vai al monitoraggio..." alt="Vai al monitoraggio..." class="badge pill bg-danger text-white text-hover-white" @click.prevent="tabIndex = 5"><i class="fas fa-user-alt-slash text-white"></i></i> Nessun. Part.</a>
                                <a v-if="slotProps.values.item[slotProps.values.field.name] === 'error_no_competitors_sent'" href="#" title="Vai al monitoraggio..." alt="Vai al monitoraggio..." class="badge pill bg-danger text-white text-hover-white" @click.prevent="tabIndex = 5"><i class="fas fa-people-arrows text-white"></i></i> Ness. Part. Inv.</a>
                                <a v-if="slotProps.values.item[slotProps.values.field.name] === 'error_competitors_partial'" href="#" title="Vai al monitoraggio..." alt="Vai al monitoraggio..." class="badge pill bg-danger text-white text-hover-white" @click.prevent="tabIndex = 5"><i class="fas fa-user-friends text-white"></i></i> Inv. Parz. Part.</a>
                            </div>
                        </td>
                    </template>

                    <template v-slot:item-actions="slotProps">
                        <td v-if="(typeof slotProps.values.item.actions !== 'undefined') && slotProps.values.item.actions.length" class="text-nowrap pr-0 text-center">
                            <a href="#" class="btn btn-icon btn-circle btn-sm btn-info mr-3" title="Gestione Partecipanti" alt="Gestione Partecipanti" @click.prevent="$router.push('/eps-subscription/event/' + slotProps.values.item.id)">
                                <i class="fas fa-sign-in-alt"></i>
                            </a>

                            <template v-for="(action, j) in slotProps.values.item.actions">
                                <a v-if="typeof action.is_external === 'undefined' || !action.is_external" href="javascript:void(0);" :class="'btn btn-icon btn-circle btn-sm btn-' + action.class + ((j < (slotProps.values.item.actions.length - 1) ? ' mr-3' : ''))" :title="action.label" :alt="action.label" @click.prevent="slotProps.values.doItemAction(action, slotProps.values.item['id'])">
                                    <i :class="action.icon"></i>
                                </a>

                                <a v-else :href="slotProps.values.appendToken(action.url)" target="_blank" :class="'btn btn-icon btn-circle btn-sm btn-' + action.class + ((j < (slotProps.values.item.actions.length - 1) ? ' mr-3' : ''))" :title="action.label" :alt="action.label">
                                    <i :class="action.icon"></i>
                                </a>
                            </template>
                        </td>
                    </template>
                </SmartTable>
            </b-tab>

            <b-tab class="border border-primary border-top-0 rounded-bottom" title="Attività Didattica" :title-link-class="linkClass(4)">
                <b-card-text>
                    <b-alert show variant="danger" dismissible>
                        <p>Attenzione, l'eliminazione degli eventi e dei partecipanti comporterà anche l’eliminazione dal Registro CONI.</p>
                        <p v-if="$store.getters.currentUser.usertype === 'Superadmin' || $store.getters.currentUser.usertype === 'Segreteria Nazionale'">Utilizzare l'azione cestino <i class="fas fa-trash-alt text-white"></i> e i pulsanti delle operazioni massive con cautela.</p>
                        <p v-if="$store.getters.currentUser.usertype === 'Comitato'">Utilizzare l'azione cestino <i class="fas fa-trash-alt text-white"></i> con cautela.</p>
                    </b-alert>
                </b-card-text>

                <SmartTable ref="regionaleducationalevents" url="/eps/regional-educational-event/index" :is_dialog="true"
                            base_url="/eps/regional-educational-event" base_path="/" :fixed_filters="[{ name: 'national', filter: false }, { name: 'idannuality', filter: idannuality }]" custom_class="mx-0 px-0" v-on:close-modal="closeModalRegionalEducationalEvents" v-on:new-user-technical-saved="updateUsersTechnical">
                    <template v-slot:title>
                        <h2 class="card-title"><span class="card-label font-weight-bolder text-dark font-size-h3">Attività Didattica</span></h2>
                    </template>

                    <template v-slot:title-actions="slotProps">
                        <div v-if="slotProps.values.top_actions.length" class="card-toolbar">
                            <a v-for="(action, index) in slotProps.values.top_actions" href="#" :class="'mt-2 btn btn-' + action.class + ' font-weight-bolder mr-3'" @click.prevent="slotProps.values.doAction(action)">
                                <i v-if="action.icon" :class="action.icon"></i>
                                {{action.label}}
                            </a>

                            <a v-if="false && ($store.getters.currentUser.usertype === 'Superadmin' || $store.getters.currentUser.usertype === 'Segreteria Nazionale')" href="#" class="mt-2 btn btn-primary font-weight-bolder mr-3" @click.prevent="sendSelectedEvents('regionaleducationalevents')">
                                <i class="fas fa-paper-plane"></i>
                                Invia Eventi Selezionati
                            </a>

                            <a v-if="false && ($store.getters.currentUser.usertype === 'Superadmin' || $store.getters.currentUser.usertype === 'Segreteria Nazionale')" href="#" class="mt-2 btn btn-danger font-weight-bolder mr-3" @click.prevent="removeSelectedEvents('regionaleducationalevents')">
                                <i class="fas fa-trash-alt"></i>
                                Rimuovi Eventi Selezionati
                            </a>

                            <a v-if="false && ($store.getters.currentUser.usertype === 'Superadmin' || $store.getters.currentUser.usertype === 'Segreteria Nazionale')" href="#" class="mt-2 btn btn-primary font-weight-bolder mr-3" @click.prevent="sendSelectedCompetitors('regionaleducationalevents')">
                                <i class="fas fa-paper-plane"></i>
                                Invia Partecipanti Selezionati
                            </a>

                            <a v-if="false && ($store.getters.currentUser.usertype === 'Superadmin' || $store.getters.currentUser.usertype === 'Segreteria Nazionale')" href="#" class="mt-2 btn btn-danger font-weight-bolder" @click.prevent="removeSelectedCompetitors('regionaleducationalevents')">
                                <i class="fas fa-trash-alt"></i>
                                Rimuovi Partecipanti Selezionati
                            </a>
                        </div>
                    </template>

                    <template v-slot:td-conierrorstate="slotProps">
                        <td class="text-center">
                            <div v-if="typeof slotProps.values !== 'undefined'">
                                <a v-if="slotProps.values.item[slotProps.values.field.name] === 'unknown'" href="#" title="Vai al monitoraggio..." alt="Vai al monitoraggio..." class="badge pill bg-info text-white text-hover-white" style="font-size: 0.9em;" @click.prevent="tabIndex = 5"><i class="fas fa-hourglass-start text-white"></i> Creato</a>
                                <a v-if="slotProps.values.item[slotProps.values.field.name] === 'partial'" href="#" title="Vai al monitoraggio..." alt="Vai al monitoraggio..." class="badge pill bg-primary text-white text-hover-white" @click.prevent="tabIndex = 5"><i class="fas fa-paper-plane text-white"></i> Invio Parziale</a>
                                <a v-if="slotProps.values.item[slotProps.values.field.name] === 'success'" href="#" title="Vai al monitoraggio..." alt="Vai al monitoraggio..." class="badge pill bg-success text-white text-hover-white" @click.prevent="tabIndex = 5"><i class="fas fa-check text-white"></i> Inviato il {{slotProps.values.item.conisenddate}}</a>

                                <a v-if="slotProps.values.item[slotProps.values.field.name] === 'error'" href="#" title="Vai al monitoraggio..." alt="Vai al monitoraggio..." class="badge pill bg-danger text-white text-hover-white" @click.prevent="tabIndex = 5"><i class="fa fa-times text-white"></i> Errore Gen.</a>
                                <a v-if="slotProps.values.item[slotProps.values.field.name] === 'mincompetitions'" href="#" title="Vai al monitoraggio..." alt="Vai al monitoraggio..." class="badge pill bg-danger text-white text-hover-white" @click.prevent="tabIndex = 5"><i class="fas fa-less-than text-white"></i> Min. Gare</a>
                                <a v-if="slotProps.values.item[slotProps.values.field.name] === 'error_no_competitors_found'" href="#" title="Vai al monitoraggio..." alt="Vai al monitoraggio..." class="badge pill bg-danger text-white text-hover-white" @click.prevent="tabIndex = 5"><i class="fas fa-user-alt-slash text-white"></i></i> Nessun. Part.</a>
                                <a v-if="slotProps.values.item[slotProps.values.field.name] === 'error_no_competitors_sent'" href="#" title="Vai al monitoraggio..." alt="Vai al monitoraggio..." class="badge pill bg-danger text-white text-hover-white" @click.prevent="tabIndex = 5"><i class="fas fa-people-arrows text-white"></i></i> Ness. Part. Inv.</a>
                                <a v-if="slotProps.values.item[slotProps.values.field.name] === 'error_competitors_partial'" href="#" title="Vai al monitoraggio..." alt="Vai al monitoraggio..." class="badge pill bg-danger text-white text-hover-white" @click.prevent="tabIndex = 5"><i class="fas fa-user-friends text-white"></i></i> Inv. Parz. Part.</a>
                            </div>
                        </td>
                    </template>

                    <template v-slot:item-actions="slotProps">
                        <td v-if="(typeof slotProps.values.item.actions !== 'undefined') && slotProps.values.item.actions.length" class="text-nowrap pr-0 text-center">
                            <a href="#" class="btn btn-icon btn-circle btn-sm btn-info mr-3" title="Gestione Partecipanti" alt="Gestione Partecipanti" @click.prevent="$router.push('/eps-subscription/event/' + slotProps.values.item.id)">
                                <i class="fas fa-sign-in-alt"></i>
                            </a>

                            <template v-for="(action, j) in slotProps.values.item.actions">
                                <a v-if="typeof action.is_external === 'undefined' || !action.is_external" href="javascript:void(0);" :class="'btn btn-icon btn-circle btn-sm btn-' + action.class + ((j < (slotProps.values.item.actions.length - 1) ? ' mr-3' : ''))" :title="action.label" :alt="action.label" @click.prevent="slotProps.values.doItemAction(action, slotProps.values.item['id'])">
                                    <i :class="action.icon"></i>
                                </a>

                                <a v-else :href="slotProps.values.appendToken(action.url)" target="_blank" :class="'btn btn-icon btn-circle btn-sm btn-' + action.class + ((j < (slotProps.values.item.actions.length - 1) ? ' mr-3' : ''))" :title="action.label" :alt="action.label">
                                    <i :class="action.icon"></i>
                                </a>
                            </template>
                        </td>
                    </template>
                </SmartTable>
            </b-tab>

            <b-tab class="border border-primary border-top-0 rounded-bottom" title="Monitoraggio" :title-link-class="linkClass(5)">
                <SmartTable ref="monitoraggio" url="/epsconimonitoring/index" :is_dialog="true"
                            base_url="/epsconimonitoring" base_path="/" :fixed_filters="[{ name: 'idannuality', filter: idannuality }]" custom_class="mx-0 px-0" v-on:close-modal="closeModal">
                    <template v-slot:title>
                        <h2 class="card-title"><span class="card-label font-weight-bolder text-dark font-size-h3">Monitoraggio Invii</span></h2>
                    </template>

                    <template v-slot:item-actions="slotProps">
                        <td class="text-nowrap pr-0 text-center">
                            <a v-if="!slotProps.values.item.errormessage" href="javascript:void(0);" class="btn btn-icon btn-circle btn-sm btn-success mr-3" title="Dettagli" alt="Dettagli" @click.prevent="slotProps.values.doItemAction(slotProps.values.item.actions[0], slotProps.values.item.id)">
                                <i class="fas fa-eye"></i>
                            </a>

                            <a v-if="slotProps.values.item.errormessage" href="javascript:void(0);" class="btn btn-icon btn-circle btn-sm btn-danger mr-3" title="Dettagli" alt="Dettagli" @click.prevent="slotProps.values.doItemAction(slotProps.values.item.actions[0], slotProps.values.item.id)">
                                <i class="fas fa-exclamation-triangle"></i>
                            </a>
                        </td>
                    </template>
                </SmartTable>
            </b-tab>
        </b-tabs>
    </b-card>
</template>

<script>
 import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
 import ApiService from "@/core/services/api.service";
 import SmartTable from "@/view/components/SmartTable.vue";
 import Swal from 'sweetalert2';

 export default {
     data() {
         return {
             tabIndex: 0,

             idannuality: '',
             annualities: [],
         };
     },

     components: {
         SmartTable,
     },

     created() {
         let tabIndex = localStorage.getItem('currentEpsTabIndex');
         if (tabIndex) {
             this.tabIndex = parseInt(tabIndex);
         }

         let idannuality = localStorage.getItem('currentEpsIdannuality');
         if (idannuality) {
             this.idannuality = parseInt(idannuality);
         }

         let url = '/eps/national-sporting-event/load-options?type=annualities';

         ApiService.query(url)
                   .then((response) => {
                       this.annualities = response.data;

                       if (this.annualities.length) {
                           if (!this.idannuality)
                           {
                               this.idannuality = this.annualities[0].id;
                           }
                       }
                   })
                   .catch((error) => {
                       console.log(error);
                   })
     },

     watch: {
         tabIndex(newValue) {
             localStorage.setItem('currentEpsTabIndex', newValue);
         },

         $route() {
             let tabIndex = localStorage.getItem('currentEpsTabIndex');
             if (tabIndex) {
                 this.tabIndex = parseInt(tabIndex);
             }

             let idannuality = localStorage.getItem('currentEpsIdannuality');
             if (idannuality) {
                 this.idannuality = parseInt(idannuality);
             }
         },

         idannuality(newValue) {
             let vm = this;
             setTimeout(function() {
                 vm.$refs.nationalsportingevents.refresh();
                 vm.$refs.nationaltrainingevents.refresh();
                 vm.$refs.regionalsportingevents.refresh();
                 vm.$refs.regionaltrainingevents.refresh();
                 vm.$refs.regionaleducationalevents.refresh();
                 if (typeof vm.$refs.monitoraggio !== "undefined") vm.$refs.monitoraggio.refresh();
             }, 200);

             localStorage.setItem('currentEpsIdannuality', newValue);
         },
     },

     mounted() {
         this.$watch(
             () => {
                 let fields = this.$refs.nationalsportingevents.componentFields;

                 if (fields.length)
                 {
                     if (this.$refs.nationalsportingevents.$refs.modalcomponent.form_type === 'edit' || this.$refs.nationalsportingevents.$refs.modalcomponent.form_type === 'copy')
                     {
                         if (this.$refs.nationalsportingevents.componentValuesloaded === null || this.$refs.nationalsportingevents.componentValuesloaded === false) {
                             return false;
                         }
                     }

                     let field = this.findField(this.$refs.nationalsportingevents.$refs.modalcomponent, 'regions');
                     let value = this.getFieldValue(field);

                     if (value && value.length) {
                         return value;
                     }

                     return null;
                 }
                 else return null;
             },
             (regions) => {
                 let fieldCommittees = this.findField(this.$refs.nationalsportingevents.$refs.modalcomponent, 'committees');
                 let fieldCommitteesValue = [];
                 if (fieldCommittees && fieldCommittees.value && fieldCommittees.value.length) fieldCommitteesValue = JSON.parse(JSON.stringify(fieldCommittees.value));

                 if (regions) {
                     let idsregion = [];

                     for (let i in regions) {
                         idsregion.push(regions[i].id);
                     }

                     let vm = this;
                     if (fieldCommittees) {
                         this.loadOptions('national-sporting-event', this.$refs.nationalsportingevents.$refs.modalcomponent, 'committees', 'regions', encodeURI(idsregion), 'committees',
                                          function(options) {
                                          },
                                          function(options) {
                                              if (fieldCommittees) {
                                                  vm.$set(fieldCommittees, 'filter_options', options);
                                                  vm.$set(fieldCommittees, 'value', []);

                                                  if (fieldCommitteesValue !== null) {

                                                      for (let i in options)
                                                      {
                                                          for (let j in fieldCommitteesValue) {
                                                              let option = options[i].committees.find(function(item) {
                                                                  return item.id === fieldCommitteesValue[j].id;
                                                              });
                                                              if (option) {
                                                                  fieldCommittees.value.push(JSON.parse(JSON.stringify(option)));
                                                              }
                                                          }
                                                      }
                                                  }
                                              }
                                          }
                         );
                     }
                 }
             }
         );

         this.$watch(
             () => {
                 let fields = this.$refs.nationaltrainingevents.componentFields;

                 if (fields.length)
                 {
                     if (this.$refs.nationaltrainingevents.$refs.modalcomponent.form_type === 'edit' || this.$refs.nationaltrainingevents.$refs.modalcomponent.form_type === 'copy')
                     {
                         if (this.$refs.nationaltrainingevents.componentValuesloaded === null || this.$refs.nationaltrainingevents.componentValuesloaded === false) {
                             return false;
                         }
                     }

                     let field = this.findField(this.$refs.nationaltrainingevents.$refs.modalcomponent, 'regions');
                     let value = this.getFieldValue(field);

                     if (value && value.length) {
                         return value;
                     }

                     return null;
                 }
                 else return null;
             },
             (regions) => {
                 let fieldCommittees = this.findField(this.$refs.nationaltrainingevents.$refs.modalcomponent, 'committees');
                 let fieldCommitteesValue = [];
                 if (fieldCommittees && fieldCommittees.value && fieldCommittees.value.length) fieldCommitteesValue = JSON.parse(JSON.stringify(fieldCommittees.value));

                 if (regions) {
                     let idsregion = [];

                     for (let i in regions) {
                         idsregion.push(regions[i].id);
                     }

                     let vm = this;
                     if (fieldCommittees) {
                         this.loadOptions('national-training-event', this.$refs.nationaltrainingevents.$refs.modalcomponent, 'committees', 'regions', encodeURI(idsregion), 'committees',
                                          function(options) {
                                          },
                                          function(options) {
                                              if (fieldCommittees) {
                                                  vm.$set(fieldCommittees, 'filter_options', options);
                                                  vm.$set(fieldCommittees, 'value', []);

                                                  if (fieldCommitteesValue !== null) {

                                                      for (let i in options)
                                                      {
                                                          for (let j in fieldCommitteesValue) {
                                                              let option = options[i].committees.find(function(item) {
                                                                  return item.id === fieldCommitteesValue[j].id;
                                                              });
                                                              if (option) {
                                                                  fieldCommittees.value.push(JSON.parse(JSON.stringify(option)));
                                                              }
                                                          }
                                                      }
                                                  }
                                              }
                                          }
                         );
                     }
                 }
             }
         );

         this.$watch(
             () => {
                 let fields = this.$refs.nationaltrainingevents.componentFields;

                 if (fields.length)
                 {
                     if (this.$refs.nationaltrainingevents.$refs.modalcomponent.form_type === 'edit' || this.$refs.nationaltrainingevents.$refs.modalcomponent.form_type === 'copy')
                     {
                         if (this.$refs.nationaltrainingevents.componentValuesloaded === null || this.$refs.nationaltrainingevents.componentValuesloaded === false) {
                             return false;
                         }
                     }

                     let field = this.findField(this.$refs.nationaltrainingevents.$refs.modalcomponent, 'idregion');
                     let value = this.getFieldValue(field);

                     if (value && value.id) return value.id;

                     return null;
                 }
                 else return null;
             },
             (newValue, oldValue) => {
                 let doUpdate;
                 let fieldProvstate = null;
                 let fieldProvstateValue = null;

                 if (newValue === false) {
                     doUpdate = false;
                 }
                 else {
                     doUpdate = true;
                     if (newValue && oldValue && newValue === oldValue) doUpdate = false;

                     if (oldValue === false) {
                         fieldProvstate = this.findField(this.$refs.nationaltrainingevents.$refs.modalcomponent, 'idprovstate');
                         fieldProvstateValue = JSON.parse(JSON.stringify(fieldProvstate.value));
                     }
                 }

                 if (doUpdate) {
                     let vm = this;
                     let value = { id: newValue };
                     if (value) {
                         vm.loadOptions('national-training-event', this.$refs.nationaltrainingevents.$refs.modalcomponent, 'provstates', 'idregion', value.id, 'idprovstate',
                                        function(options) {
                                            options.unshift({
                                                id: 0,
                                                provstatecode: 'Seleziona una provincia',
                                            });
                                        },
                                        function(options) {
                                            fieldProvstate = vm.findField(vm.$refs.nationaltrainingevents.$refs.modalcomponent, 'idprovstate');

                                            if (fieldProvstate) {
                                                vm.$set(fieldProvstate, 'filter_options', options);

                                                if (typeof fieldProvstate.value !== "undefined") {
                                                    if (fieldProvstateValue !== null) {
                                                        fieldProvstate.value = fieldProvstateValue;
                                                    }
                                                    else {
                                                        fieldProvstate.value = JSON.parse(JSON.stringify(options[0]));
                                                    }
                                                }
                                            }
                                        }
                         );
                     }
                 }
             }
         );

         this.$watch(
             () => {
                 let fields = this.$refs.nationaltrainingevents.componentFields;

                 if (fields.length)
                 {
                     if (this.$refs.nationaltrainingevents.$refs.modalcomponent.form_type === 'edit' || this.$refs.nationaltrainingevents.$refs.modalcomponent.form_type === 'copy')
                     {
                         if (this.$refs.nationaltrainingevents.componentValuesloaded === null || this.$refs.nationaltrainingevents.componentValuesloaded === false) {
                             return false;
                         }
                     }

                     let field = this.findField(this.$refs.nationaltrainingevents.$refs.modalcomponent, 'idprovstate');
                     let value = this.getFieldValue(field);

                     if (typeof value !== "undefined" && value && value.id) return value.id;

                     return null;
                 }
                 else return null;
             },
             (newValue, oldValue) => {
                 let doUpdate;
                 let fieldCity = null;
                 let fieldCityValue = null;

                 if (newValue === false) {
                     doUpdate = false;
                 }
                 else {
                     doUpdate = true;
                     if (newValue && oldValue && newValue === oldValue) doUpdate = false;

                     if (oldValue === false) {
                         fieldCity = this.findField(this.$refs.nationaltrainingevents.$refs.modalcomponent, 'idcity');
                         fieldCityValue = JSON.parse(JSON.stringify(fieldCity.value));
                     }
                 }

                 if (doUpdate) {
                     let vm = this;
                     let value = { id: newValue };
                     if (value) {
                         vm.loadOptions('national-training-event', this.$refs.nationaltrainingevents.$refs.modalcomponent, 'cities', 'idprovstate', value.id, 'idcity',
                                        function(options) {
                                            options.unshift({
                                                id: 0,
                                                cityname: 'Seleziona una città',
                                            });
                                        },
                                        function (options) {
                                            fieldCity = vm.findField(vm.$refs.nationaltrainingevents.$refs.modalcomponent, 'idcity');

                                            if (fieldCity) {
                                                vm.$set(fieldCity, 'filter_options', options);

                                                if (typeof fieldCity.value !== "undefined") {
                                                    if (fieldCityValue !== null) {
                                                        fieldCity.value = fieldCityValue;
                                                    }
                                                    else {
                                                        fieldCity.value = JSON.parse(JSON.stringify(options[0]));
                                                    }
                                                }
                                            }
                                        }
                         );
                     }
                 }
             }
         );

         this.$watch(
             () => {
                 let fields = this.$refs.regionaltrainingevents.componentFields;

                 if (fields.length)
                 {
                     if (this.$refs.regionaltrainingevents.$refs.modalcomponent.form_type === 'edit' || this.$refs.regionaltrainingevents.$refs.modalcomponent.form_type === 'copy')
                     {
                         if (this.$refs.regionaltrainingevents.componentValuesloaded === null || this.$refs.regionaltrainingevents.componentValuesloaded === false) {
                             return false;
                         }
                     }

                     let field = this.findField(this.$refs.regionaltrainingevents.$refs.modalcomponent, 'idregion');
                     let value = this.getFieldValue(field);

                     if (value && value.id) return value.id;

                     return null;
                 }
                 else return null;
             },
             (newValue, oldValue) => {
                 let doUpdate;
                 let fieldProvstate = null;
                 let fieldProvstateValue = null;

                 if (newValue === false) {
                     doUpdate = false;
                 }
                 else {
                     doUpdate = true;
                     if (newValue && oldValue && newValue === oldValue) doUpdate = false;

                     if (oldValue === false) {
                         fieldProvstate = this.findField(this.$refs.regionaltrainingevents.$refs.modalcomponent, 'idprovstate');
                         fieldProvstateValue = JSON.parse(JSON.stringify(fieldProvstate.value));
                     }
                 }

                 if (doUpdate) {
                     let vm = this;
                     let value = { id: newValue };
                     if (value) {
                         vm.loadOptions('regional-training-event', this.$refs.regionaltrainingevents.$refs.modalcomponent, 'provstates', 'idregion', value.id, 'idprovstate',
                                        function(options) {
                                            options.unshift({
                                                id: 0,
                                                provstatecode: 'Seleziona una provincia',
                                            });
                                        },
                                        function(options) {
                                            fieldProvstate = vm.findField(vm.$refs.regionaltrainingevents.$refs.modalcomponent, 'idprovstate');

                                            if (fieldProvstate) {
                                                vm.$set(fieldProvstate, 'filter_options', options);

                                                if (typeof fieldProvstate.value !== "undefined") {
                                                    if (fieldProvstateValue !== null) {
                                                        fieldProvstate.value = fieldProvstateValue;
                                                    }
                                                    else {
                                                        fieldProvstate.value = JSON.parse(JSON.stringify(options[0]));
                                                    }
                                                }
                                            }
                                        }
                         );
                     }
                 }
             }
         );

         this.$watch(
             () => {
                 let fields = this.$refs.regionaltrainingevents.componentFields;

                 if (fields.length)
                 {
                     if (this.$refs.regionaltrainingevents.$refs.modalcomponent.form_type === 'edit' || this.$refs.regionaltrainingevents.$refs.modalcomponent.form_type === 'copy')
                     {
                         if (this.$refs.regionaltrainingevents.componentValuesloaded === null || this.$refs.regionaltrainingevents.componentValuesloaded === false) {
                             return false;
                         }
                     }

                     let field = this.findField(this.$refs.regionaltrainingevents.$refs.modalcomponent, 'idprovstate');
                     let value = this.getFieldValue(field);

                     if (typeof value !== "undefined" && value && value.id) return value.id;

                     return null;
                 }
                 else return null;
             },
             (newValue, oldValue) => {
                 let doUpdate;
                 let fieldCity = null;
                 let fieldCityValue = null;

                 if (newValue === false) {
                     doUpdate = false;
                 }
                 else {
                     doUpdate = true;
                     if (newValue && oldValue && newValue === oldValue) doUpdate = false;

                     if (oldValue === false) {
                         fieldCity = this.findField(this.$refs.regionaltrainingevents.$refs.modalcomponent, 'idcity');
                         fieldCityValue = JSON.parse(JSON.stringify(fieldCity.value));
                     }
                 }

                 if (doUpdate) {
                     let vm = this;
                     let value = { id: newValue };
                     if (value) {
                         vm.loadOptions('regional-training-event', this.$refs.regionaltrainingevents.$refs.modalcomponent, 'cities', 'idprovstate', value.id, 'idcity',
                                        function(options) {
                                            options.unshift({
                                                id: 0,
                                                cityname: 'Seleziona una città',
                                            });
                                        },
                                        function (options) {
                                            fieldCity = vm.findField(vm.$refs.regionaltrainingevents.$refs.modalcomponent, 'idcity');

                                            if (fieldCity) {
                                                vm.$set(fieldCity, 'filter_options', options);

                                                if (typeof fieldCity.value !== "undefined") {
                                                    if (fieldCityValue !== null) {
                                                        fieldCity.value = fieldCityValue;
                                                    }
                                                    else {
                                                        fieldCity.value = JSON.parse(JSON.stringify(options[0]));
                                                    }
                                                }
                                            }
                                        }
                         );
                     }
                 }
             }
         );

         this.$watch(
             () => {
                 let fields = this.$refs.regionaleducationalevents.componentFields;

                 if (fields.length)
                 {
                     if (this.$refs.regionaleducationalevents.$refs.modalcomponent.form_type === 'edit' || this.$refs.regionaleducationalevents.$refs.modalcomponent.form_type === 'copy')
                     {
                         if (this.$refs.regionaleducationalevents.componentValuesloaded === null || this.$refs.regionaleducationalevents.componentValuesloaded === false) {
                             return false;
                         }
                     }

                     let field = this.findField(this.$refs.regionaleducationalevents.$refs.modalcomponent, 'idregion');
                     let value = this.getFieldValue(field);

                     if (value && value.id) return value.id;

                     return null;
                 }
                 else return null;
             },
             (newValue, oldValue) => {
                 let doUpdate;
                 let fieldProvstate = null;
                 let fieldProvstateValue = null;

                 if (newValue === false) {
                     doUpdate = false;
                 }
                 else {
                     doUpdate = true;
                     if (newValue && oldValue && newValue === oldValue) doUpdate = false;

                     if (oldValue === false) {
                         fieldProvstate = this.findField(this.$refs.regionaleducationalevents.$refs.modalcomponent, 'idprovstate');
                         fieldProvstateValue = JSON.parse(JSON.stringify(fieldProvstate.value));
                     }
                 }

                 if (doUpdate) {
                     let vm = this;
                     let value = { id: newValue };
                     if (value) {
                         vm.loadOptions('regional-educational-event', this.$refs.regionaleducationalevents.$refs.modalcomponent, 'provstates', 'idregion', value.id, 'idprovstate',
                                        function(options) {
                                            options.unshift({
                                                id: 0,
                                                provstatecode: 'Seleziona una provincia',
                                            });
                                        },
                                        function(options) {
                                            fieldProvstate = vm.findField(vm.$refs.regionaleducationalevents.$refs.modalcomponent, 'idprovstate');

                                            if (fieldProvstate) {
                                                vm.$set(fieldProvstate, 'filter_options', options);

                                                if (typeof fieldProvstate.value !== "undefined") {
                                                    if (fieldProvstateValue !== null) {
                                                        fieldProvstate.value = fieldProvstateValue;
                                                    }
                                                    else {
                                                        fieldProvstate.value = JSON.parse(JSON.stringify(options[0]));
                                                    }
                                                }
                                            }
                                        }
                         );
                     }
                 }
             }
         );

         this.$watch(
             () => {
                 let fields = this.$refs.regionaleducationalevents.componentFields;

                 if (fields.length)
                 {
                     if (this.$refs.regionaleducationalevents.$refs.modalcomponent.form_type === 'edit' || this.$refs.regionaleducationalevents.$refs.modalcomponent.form_type === 'copy')
                     {
                         if (this.$refs.regionaleducationalevents.componentValuesloaded === null || this.$refs.regionaleducationalevents.componentValuesloaded === false) {
                             return false;
                         }
                     }

                     let field = this.findField(this.$refs.regionaleducationalevents.$refs.modalcomponent, 'idprovstate');
                     let value = this.getFieldValue(field);

                     if (typeof value !== "undefined" && value && value.id) return value.id;

                     return null;
                 }
                 else return null;
             },
             (newValue, oldValue) => {
                 let doUpdate;
                 let fieldCity = null;
                 let fieldCityValue = null;

                 if (newValue === false) {
                     doUpdate = false;
                 }
                 else {
                     doUpdate = true;
                     if (newValue && oldValue && newValue === oldValue) doUpdate = false;

                     if (oldValue === false) {
                         fieldCity = this.findField(this.$refs.regionaleducationalevents.$refs.modalcomponent, 'idcity');
                         fieldCityValue = JSON.parse(JSON.stringify(fieldCity.value));
                     }
                 }

                 if (doUpdate) {
                     let vm = this;
                     let value = { id: newValue };
                     if (value) {
                         vm.loadOptions('regional-educational-event', this.$refs.regionaleducationalevents.$refs.modalcomponent, 'cities', 'idprovstate', value.id, 'idcity',
                                        function(options) {
                                            options.unshift({
                                                id: 0,
                                                cityname: 'Seleziona una città',
                                            });
                                        },
                                        function (options) {
                                            fieldCity = vm.findField(vm.$refs.regionaleducationalevents.$refs.modalcomponent, 'idcity');

                                            if (fieldCity) {
                                                vm.$set(fieldCity, 'filter_options', options);

                                                if (typeof fieldCity.value !== "undefined") {
                                                    if (fieldCityValue !== null) {
                                                        fieldCity.value = fieldCityValue;
                                                    }
                                                    else {
                                                        fieldCity.value = JSON.parse(JSON.stringify(options[0]));
                                                    }
                                                }
                                            }
                                        }
                         );
                     }
                 }
             }
         );

         this.$watch(
             () => {
                 let fields = this.$refs.regionaleducationalevents.componentFields;

                 if (fields.length)
                 {
                     if (this.$refs.regionaleducationalevents.$refs.modalcomponent.form_type === 'edit' || this.$refs.regionaleducationalevents.$refs.modalcomponent.form_type === 'copy')
                     {
                         if (this.$refs.regionaleducationalevents.componentValuesloaded === null || this.$refs.regionaleducationalevents.componentValuesloaded === false) {
                             return false;
                         }
                     }

                     let field = this.findField(this.$refs.regionaleducationalevents.$refs.modalcomponent, 'idclubusertechnical');
                     let value = this.getFieldValue(field);

                     if (value && value.id) return value.id;

                     return null;
                 }
                 else return null;
             },
             (newValue, oldValue) => {
                 this.$refs.regionaleducationalevents.$refs.modalcomponent.$refs.formfields.$refs['after-component-idusertechnical'][0].idsportingclub = newValue

                 let doUpdate;
                 let fieldUsertechnical = null;
                 let fieldUsertechnicalValue = null;

                 if (newValue === false) {
                     doUpdate = false;
                 }
                 else {
                     doUpdate = true;
                     if (newValue && oldValue && newValue === oldValue) doUpdate = false;

                     if (oldValue === false) {
                         fieldUsertechnical = this.findField(this.$refs.regionaleducationalevents.$refs.modalcomponent, 'idusertechnical');
                         fieldUsertechnicalValue = JSON.parse(JSON.stringify(fieldUsertechnical.value));
                     }
                 }

                 if (doUpdate) {
                     let vm = this;
                     let value = { id: newValue };
                     if (value) {
                         vm.loadOptions('regional-educational-event', this.$refs.regionaleducationalevents.$refs.modalcomponent, 'sportingclubsboards', 'idclubusertechnical', value.id, 'idusertechnical',
                                        function(options) {
                                            options.unshift({
                                                id: 0,
                                                label: 'Seleziona un tecnico',
                                            });
                                        },
                                        function(options) {
                                            fieldUsertechnical = vm.findField(vm.$refs.regionaleducationalevents.$refs.modalcomponent, 'idusertechnical');

                                            if (fieldUsertechnical) {
                                                vm.$set(fieldUsertechnical, 'filter_options', options);

                                                if (typeof fieldUsertechnical.value !== "undefined") {
                                                    if (fieldUsertechnicalValue !== null) {
                                                        fieldUsertechnical.value = fieldUsertechnicalValue;
                                                    }
                                                    else {
                                                        fieldUsertechnical.value = JSON.parse(JSON.stringify(options[0]));
                                                    }
                                                }
                                            }
                                        }
                         );
                     }
                 }
             }
         );

         this.$watch(
             () => {
                 let fields = this.$refs.regionaleducationalevents.componentFields;

                 if (fields.length)
                 {
                     if (this.$refs.regionaleducationalevents.$refs.modalcomponent.form_type === 'edit' || this.$refs.regionaleducationalevents.$refs.modalcomponent.form_type === 'copy')
                     {
                         if (this.$refs.regionaleducationalevents.componentValuesloaded === null || this.$refs.regionaleducationalevents.componentValuesloaded === false) {
                             return false;
                         }
                     }

                     let field = this.findField(this.$refs.regionaleducationalevents.$refs.modalcomponent, 'idusertechnical');
                     let value = this.getFieldValue(field);

                     if (value && value.id) return value.id;

                     return null;
                 }
                 else return null;
             },
             (newValue, oldValue) => {
                 let doUpdate;

                 if (newValue === false) {
                     doUpdate = false;
                 }
                 else {
                     doUpdate = true;
                     if (newValue && oldValue && newValue === oldValue) doUpdate = false;
                 }

                 if (doUpdate) {
                     if (newValue !== null) {
                         let field;
                         let idclubusertechnical;
                         let idusertechnical;
                         let cardnumber;

                         idusertechnical = newValue;

                         field = this.findField(this.$refs.regionaleducationalevents.$refs.modalcomponent, 'idclubusertechnical');
                         idclubusertechnical = field.value.id;

                         field = this.findField(this.$refs.regionaleducationalevents.$refs.modalcomponent, 'cardnumber');

                         let url = '/eps/subscription/get-board-cardnumber?idclubusertechnical=' + idclubusertechnical + '&idusertechnical=' + newValue;

                         ApiService.get(url)
                                   .then((response) => {
                                       cardnumber = response.data;
                                       this.$set(field, 'value', cardnumber);
                                   })
                                   .catch(({response}) => {
                                       console.log(response);
                                   });
                     }
                     else {
                         let field;
                         field = this.findField(this.$refs.regionaleducationalevents.$refs.modalcomponent, 'cardnumber');
                         field.value = '';
                     }
                 }
             }
         );

         this.$store.dispatch(SET_BREADCRUMB, [
             { title: "Portale EPS", route: "/eps-portal" },
         ]);
     },

     methods: {
         linkClass(idx) {
             if (this.tabIndex === idx) {
                 if (idx < 2) {
                     return ['text-success', 'border-top-success', 'border-left-success', 'border-right-success', 'text-center', 'font-size-h5'];
                 }
                 else {
                     return ['text-primary', 'border-top-primary', 'border-left-primary', 'border-right-primary', 'text-center', 'font-size-h5'];
                 }
             } else if (idx < 2) {
                 return ['bg-success', 'text-white', 'font-size-h5']
             } else {
                 return ['bg-primary', 'text-white', 'font-size-h5']
             }
         },

         closeModal() {
         },

         closeModalNationalSportingEvents() {
             this.$refs.nationalsportingevents.refresh();
         },

         closeModalNationalTrainingEvents() {
             this.$refs.nationaltrainingevents.refresh();
         },

         closeModalRegionalSportingEvents() {
             this.$refs.regionalsportingevents.refresh();
         },

         closeModalRegionalTrainingEvents() {
             this.$refs.regionaltrainingevents.refresh();
         },

         closeModalRegionalEducationalEvents() {
             this.$refs.regionaleducationalevents.refresh();
         },

         findField(obj, name) {
             if (typeof obj !== "undefined" && obj && typeof obj.fields !== "undefined" && obj.fields) {
                 return obj.fields.find(item => item.name === name);
             }

             return null;
         },

         getFieldValue(field) {
             if (field) {
                 let value = typeof field.value !== "undefined" ? field.value : null;

                 if (value !== null) {
                     return (JSON.parse(JSON.stringify(value)));
                 }
             }

             return null;
         },

         setFieldVisible(obj, name, visible) {
             if (typeof obj.fields !== "undefined") {
                 let fieldIndex = obj.fields.findIndex(item => item.name === name);
                 if (fieldIndex) {
                     if (typeof obj.fields !== "undefined" && typeof obj.fields[fieldIndex] !== "undefined") this.$set(obj.fields[fieldIndex], 'is_visible_for_store', visible);
                     if (typeof obj.fields !== "undefined" && typeof obj.fields[fieldIndex] !== "undefined") this.$set(obj.fields[fieldIndex], 'is_visible_for_update', visible);
                 }
             }
         },

         setFieldAttribute(obj, name, attribute, value) {
             let fieldIndex = obj.fields.findIndex(item => item.name === name);
             if (fieldIndex) {
                 this.$set(obj.fields[fieldIndex], attribute, value);
             }
         },

         loadOptions(baseurl, obj, type, parameterName, parameterValue, fieldName, callbackEmptyOption, callbackOptions = null) {
             let url = '/eps/' + baseurl + '/load-options?type=' + type + '&' + parameterName + '=' + parameterValue;

             ApiService.query(url)
                       .then((response) => {
                           let options = response.data;

                           if (typeof options === "undefined") options = [];

                           callbackEmptyOption(options);

                           if (callbackOptions !== null) {
                               callbackOptions(options);
                           }
                           else {
                               this.setFieldAttribute(obj, fieldName, 'filter_options', options);
                           }
                       })
                       .catch((error) => {
                           console.log(error);

                           let options = [];

                           callbackEmptyOption(options);

                           if (callbackOptions !== null) {
                               callbackOptions(options);
                           }
                           else {
                               this.setFieldAttribute(obj, fieldName, 'filter_options', options);
                           }
                       })
         },

         getApiUrl() {
             return ApiService.getApiUrl();
         },

         sendSelectedEvents(table) {
             Swal.fire({
                 title: 'Conferma invio eventi selezionati',
                 text: "Sei sicuro di voler inviare gli eventi selezionati?",
                 icon: 'warning',
                 showCancelButton: true,
                 showConfirmButton: true,
                 confirmButtonText: 'Conferma',
                 cancelButtonText: 'Annulla',
             }).then((result) => {
                 if (result.isConfirmed) {
                     this.loading = true;

                     let events = [];
                     for (let i in this.$refs[table].items) {
                         if (this.$refs[table].items[i].checked) events.push({ idevent: this.$refs[table].items[i].id } );
                     }

                     if (events.length) {
                         let url = '/epsconimonitoring/send-selected-events';
                         let data = {
                             events: events,
                         };

                         ApiService.post(url, data)
                                   .then((response) => {
                                       if (response.data.status === 'OK') {
                                           this.$bvToast.toast('Eventi inviati correttamente', {
                                               title: 'Eventi inviati correttamente',
                                               variant: 'success',
                                               solid: true
                                           });

                                           for (let i in this.$refs[table].items) {
                                               if (this.$refs[table].items[i].checked) this.$refs[table].items[i].checked = false;
                                           }
                                       }

                                       if (response.data.status === 'KO') {
                                           this.$bvToast.toast('Si sono verificati degli errori nell\'invio degli eventi.', {
                                               title: 'Errore nell\'invio degli eventi',
                                               variant: 'danger',
                                               solid: true
                                           });
                                       }
                                   })
                                   .catch(({response}) => {
                                       console.log(response);
                                   });
                     }
                     else
                     {
                         Swal.fire({
                             title: 'Nessun evento selezionato',
                             text: "Per effettuare l\'invio selezionare almeno un evento",
                             icon: 'warning',
                             showConfirmButton: true,
                             confirmButtonText: 'Ok',
                         });
                     }

                     this.loading = false;
                 }
             });
         },

         removeSelectedEvents(table) {
             Swal.fire({
                 title: 'Conferma rimozione eventi selezionati',
                 text: "Sei sicuro di voler rimuovere gli eventi selezionati?",
                 icon: 'warning',
                 showCancelButton: true,
                 showConfirmButton: true,
                 confirmButtonText: 'Conferma',
                 cancelButtonText: 'Annulla',
             }).then((result) => {
                 if (result.isConfirmed) {
                     this.loading = true;

                     let events = [];
                     for (let i in this.$refs[table].items) {
                         if (this.$refs[table].items[i].checked) events.push({ idevent: this.$refs[table].items[i].id } );
                     }

                     if (events.length) {
                         let url = '/epsconimonitoring/remove-selected-events';
                         let data = {
                             events: events,
                         };

                         ApiService.post(url, data)
                                   .then((response) => {
                                       if (response.data.status === 'OK') {
                                           this.$bvToast.toast('Eventi rimossi correttamente', {
                                               title: 'Eventi rimossi correttamente',
                                               variant: 'success',
                                               solid: true
                                           });

                                           for (let i in this.$refs[table].items) {
                                               if (this.$refs[table].items[i].checked) this.$refs[table].items[i].checked = false;
                                           }
                                       }

                                       if (response.data.status === 'KO') {
                                           this.$bvToast.toast('Si sono verificati degli errori nella rimozione degli eventi.', {
                                               title: 'Errore nella rimozione degli eventi',
                                               variant: 'danger',
                                               solid: true
                                           });
                                       }
                                   })
                                   .catch(({response}) => {
                                       console.log(response);
                                   });
                     }
                     else
                     {
                         Swal.fire({
                             title: 'Nessun evento selezionato',
                             text: "Per effettuare la rimozione selezionare almeno un evento",
                             icon: 'warning',
                             showConfirmButton: true,
                             confirmButtonText: 'Ok',
                         });
                     }

                     this.loading = false;
                 }
             });
         },

         sendSelectedCompetitors(table) {
             Swal.fire({
                 title: 'Conferma invio partecipanti selezionati',
                 text: "Sei sicuro di voler inviare i partecipanti per gli eventi selezionati?",
                 icon: 'warning',
                 showCancelButton: true,
                 showConfirmButton: true,
                 confirmButtonText: 'Conferma',
                 cancelButtonText: 'Annulla',
             }).then((result) => {
                 if (result.isConfirmed) {
                     this.loading = true;

                     let events = [];
                     for (let i in this.$refs[table].items) {
                         if (this.$refs[table].items[i].checked) events.push({ idevent: this.$refs[table].items[i].id } );
                     }

                     if (events.length) {
                         let url = '/epsconimonitoring/send-selected-competitors';
                         let data = {
                             events: events,
                         };

                         ApiService.post(url, data)
                                   .then((response) => {
                                       if (response.data.status === 'OK') {
                                           this.$bvToast.toast('Partecipanti inviati correttamente', {
                                               title: 'Partecipanti inviati correttamente',
                                               variant: 'success',
                                               solid: true
                                           });

                                           for (let i in this.$refs[table].items) {
                                               if (this.$refs[table].items[i].checked) this.$refs[table].items[i].checked = false;
                                           }
                                       }

                                       if (response.data.status === 'KO') {
                                           this.$bvToast.toast('Si sono verificati degli errori nell\'invio dei partecipanti.', {
                                               title: 'Errore nell\'invio dei partecipanti',
                                               variant: 'danger',
                                               solid: true
                                           });
                                       }
                                   })
                                   .catch(({response}) => {
                                       console.log(response);
                                   });
                     }
                     else
                     {
                         Swal.fire({
                             title: 'Nessun evento selezionato',
                             text: "Per effettuare l\'invio selezionare almeno un evento",
                             icon: 'warning',
                             showConfirmButton: true,
                             confirmButtonText: 'Ok',
                         });
                     }

                     this.loading = false;
                 }
             });
         },

         removeSelectedCompetitors(table) {
             Swal.fire({
                 title: 'Conferma rimozione partecipanti selezionati',
                 text: "Sei sicuro di voler rimuovere i partecipanti per gli eventi selezionati?",
                 icon: 'warning',
                 showCancelButton: true,
                 showConfirmButton: true,
                 confirmButtonText: 'Conferma',
                 cancelButtonText: 'Annulla',
             }).then((result) => {
                 if (result.isConfirmed) {
                     this.loading = true;

                     let events = [];
                     for (let i in this.$refs[table].items) {
                         if (this.$refs[table].items[i].checked) events.push({ idevent: this.$refs[table].items[i].id } );
                     }

                     if (events.length) {
                         let url = '/epsconimonitoring/remove-selected-competitors';
                         let data = {
                             events: events,
                         };

                         ApiService.post(url, data)
                                   .then((response) => {
                                       if (response.data.status === 'OK') {
                                           this.$bvToast.toast('Partecipanti rimossi correttamente', {
                                               title: 'Partecipanti rimossi correttamente',
                                               variant: 'success',
                                               solid: true
                                           });

                                           for (let i in this.$refs[table].items) {
                                               if (this.$refs[table].items[i].checked) this.$refs[table].items[i].checked = false;
                                           }
                                       }

                                       if (response.data.status === 'KO') {
                                           this.$bvToast.toast('Si sono verificati degli errori nella rimozione dei partecipanti.', {
                                               title: 'Errore nella rimozione dei partecipanti',
                                               variant: 'danger',
                                               solid: true
                                           });
                                       }
                                   })
                                   .catch(({response}) => {
                                       console.log(response);
                                   });
                     }
                     else
                     {
                         Swal.fire({
                             title: 'Nessun evento selezionato',
                             text: "Per effettuare la rimozione selezionare almeno un evento",
                             icon: 'warning',
                             showConfirmButton: true,
                             confirmButtonText: 'Ok',
                         });
                     }

                     this.loading = false;
                 }
             });
         },

         updateUsersTechnical() {
             let field = this.findField(this.$refs.regionaleducationalevents.$refs.modalcomponent, 'idclubusertechnical');
             let value = this.getFieldValue(field);

             let vm = this;

             this.loadOptions('regional-educational-event', this.$refs.regionaleducationalevents.$refs.modalcomponent, 'sportingclubsboards', 'idclubusertechnical', value.id, 'idusertechnical',
                              function(options) {
                                  options.unshift({
                                      id: 0,
                                      label: 'Seleziona un tecnico',
                                  });
                              },
                              function(options) {
                                  let fieldUsertechnical = vm.findField(vm.$refs.regionaleducationalevents.$refs.modalcomponent, 'idusertechnical');

                                  if (fieldUsertechnical) {
                                      vm.$set(fieldUsertechnical, 'filter_options', options);
                                  }
                              }
             );
         },
     },
 };
</script>

<style>
 ul.nav-tabs .nav-item a.nav-link {
     height: 100%;
 }
</style>
